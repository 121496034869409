import FlipBox from "../components/FlipBox/FlipBox";

export default function NovelPage() {
    return (
      <>
        <section className="">
        <FlipBox/>
        </section>
      </>
    )
  }
  